import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrNewAttendeeForm from '@/modules/level-two/views/event/attendees/create-attendee/new-attendee.form.vue'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'

interface ParticipantSelectOption {
  text: string;
  value: string;
}

interface Session {
  name: string;
  barcode: string;
  room: string;
  capacity: string | number;
  start_date: string;
  end_date: string;
  scan_modes: string | number;
  auto_create_checkout_time: string | number;
  minutes_after_checkin_time: string | number | null;
  category: string | null;
  credits: string | null;
  alt_credits: string | null;
  ceu_min_time: string | number;
  external_id: string | null;
  [key: string]: any;
}

@Component({
  name: 'GtrEventEditSessionView',
  components: {
    'gtr-new-attendee-form': GtrNewAttendeeForm
  },
  computed: {
    ...mapState('module', ['activatedEventModules']),
    ...mapState('pricing', ['pricing_tiers']),
    ...mapState('sessions', ['activities'])
  }
})
export default class GtrEventSessionDetailView extends GtrSuper {
  activatedEventModules!: any;
  currentEvent: any

  data () {
    return {
      loading: false,
      submitting: false,
      currentTab: 0,
      registration_types: [
        {
          text: 'Default',
          value: '_default'
        }
      ],
      optionsYesNo: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      options10: [
        {
          text: 'Yes',
          value: 1
        },
        {
          text: 'No',
          value: 0
        }
      ],
      activities_data: [],
      activities_be: [],
      session: {
        name: null,
        barcode: null,
        room: null,
        capacity: null,
        start_date: null,
        end_date: null,
        scan_modes: null,
        auto_create_checkout_time: null,
        minutes_after_checkin_time: null,
        external_id: null,
        category: null,
        credits: null,
        alt_credits: null,
        ceu_min_time: null
      },
      regTypeCaps: {},
      speaker_participant_uuid: null,
      original_speaker_participant_uuid: null,
      original_speaker_uuid: null,
      showNewAttendeeForm: false,
      custom_fields: [],
      event_uuid: this.$route.params.event_uuid,
      session_uuid: this.$route.params.session_uuid,
      icons: [{ text: 'Drink', value: 'Drink' }, { text: 'Meal', value: 'Meal' }],
      confirmEnabled: [{ text: 'Enabled', value: 1 }, { text: 'Disabled', value: 0 }],
      modals: {},
      option: {
        taxed: 0,
        popup_details: {
          title: '',
          body: ''
        },
        pricing: [],
        visible: 1,
        regtype_specific: false,
        reg_types: []
      },
      pricingTiersForSelect: [],
      pricingTiers: []
    }
  }

  async mounted () {
    try {
      this.$data.loading = true
      this.currentEvent = this.$store.state.event.event
      await this.$store.dispatch('event/fetchEventModules', this.$route.params.event_uuid)
      await this.$store.dispatch('pricing/getPricingTiers', { event_uuid: this.$route.params.event_uuid })
      await this.fetchRegistrationTypes()
      await this.fetchCustomFields()
      await this.fetchParticipantsList()
      if (this.$data.session_uuid) {
        await this.fetchSession()
        await this.fetchActivities(this.$data.session_uuid)
      } else {
        this.autoGenerateBarcode()
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  @Watch('activities')
  onActivitiesChange (payload: any) {
    if (Array.isArray(payload)) {
      this.$data.activities_data = payload
    }
  }

  @Watch('pricing_tiers')
  onPricingTiersChange (payload: any) {
    this.$data.pricingTiers = payload
    payload.forEach((tier: any) => {
      this.$data.pricingTiersForSelect.push({
        text: tier.name,
        value: tier.uuid
      })
    })
  }

  private async fetchRegistrationTypes () {
    const response = await this.$store.dispatch('option/getOptionGroupByName', { event_uuid: this.$data.event_uuid, option_group_name: 'Registration Types' })
    const registration_types = response.data.options
    if (Array.isArray(registration_types)) {
      registration_types.forEach(regType => {
        this.$data.registration_types.push({
          text: regType.name,
          value: regType.uuid
        })
      })
    }
  }

  private async fetchSession () {
    this.$data.loading = true
    const response = await this.$store.dispatch('sessions/getSession', { event_uuid: this.$data.event_uuid, session_uuid: this.$data.session_uuid })
    const session = response.data
    if (session) {
      this.$data.session.name = session.name
      this.$data.session.barcode = session.barcode
      this.$data.session.room = session.room
      this.$data.session.capacity = session.capacity
      this.$data.session.start_date = moment(session.start_date).tz(this.currentEvent.timezone).format('YYYY-MM-DD HH:mm')
      this.$data.session.end_date = moment(session.end_date).tz(this.currentEvent.timezone).format('YYYY-MM-DD HH:mm')
      this.$data.session.scan_modes = session.scan_modes
      this.$data.session.auto_create_checkout_time = session.auto_create_checkout_time
      this.$data.session.minutes_after_checkin_time = session.minutes_after_checkin_time
      this.$data.session.show_if = session.show_if
      this.$data.session.category = session.category
      this.$data.session.credits = session.credits
      this.$data.session.alt_credits = session.alt_credits
      this.$data.session.ceu_min_time = session.ceu_min_time
      this.$data.session.external_id = session.external_id

      this.$data.original_speaker_uuid = session.session_speakers[0]?.uuid
      this.$data.original_speaker_participant_uuid = session.session_speakers[0]?.speaker_condensed.uuid

      this.$data.speaker_participant_uuid = this.$data.original_speaker_participant_uuid

      this.$data.option.taxed = session.option.taxed
      this.$data.option.popup_details = session.option.popup_details ?? { title: '', body: '' }
      this.$data.option.pricing = session.option.pricing ?? []
      this.$data.option.visible = session.option.visible ?? 1
      this.$data.option.regtype_specific = session.option.regtype_specific ?? false
      this.$data.option.reg_types = session.option.reg_types ?? []
    }
  }

  private async fetchParticipantsList () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('attendee/fetchAttendeesListView', { event_uuid: this.$route.params.event_uuid, limit: 200 })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchActivities (session_uuid: string) {
    try {
      this.$data.loading = true
      this.$data.activities_be = this._.cloneDeep(await this.$store.dispatch('sessions/fetchActivities', { event_uuid: this.$route.params.event_uuid, session_uuid }))
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  /**
   * Array of participant UUID's and names
   */
  get participantSelectOptions (): ParticipantSelectOption[] {
    let participants: ParticipantSelectOption[] = []

    if (Array.isArray(this.$store.state.attendee.attendees)) {
      participants = this.$store.state.attendee.attendees.map(participant => {
        return {
          value: participant.uuid,
          text: (participant.first_name || '') + ' ' + (participant.last_name || '')
        }
      })
    }

    return participants
  }

  get surveyModuleActive () {
    return this.activatedEventModules?.SURVEYS?.enabled ?? false
  }

  get registrationModuleActive () {
    return this.activatedEventModules?.REGISTRATION?.enabled ?? false
  }

  /**
   * Fetch custom fields for new attendee form.
   */
  async fetchCustomFields () {
    this.$data.loading = true
    const temp_custom_fields = await this.$store.dispatch('event/loadCustomFields', { event_uuid: this.$route.params.event_uuid })
    this.$data.custom_fields = []
    temp_custom_fields.forEach(item => {
      if (item.field.includes('custom_')) {
        this.$data.custom_fields.push(item)
      }
    })
  }

  addActivity () {
    const pricing_item = {
      uuid: Vue.prototype.$uuid.v4(),
      name: '',
      type: '',
      caps: { _default: null },
      ticket_redeemed: '{{first name}} {{last name}} has {{tickets}} left',
      last_ticket_redeemed: 'Last ticket redeemed, {{first name}} {{last name}} has 0 tickets left',
      confirm_activity_button_verbiage: 'Redeem',
      confirm_activity_button_enabled: 0
    }
    this.$data.activities_data.push(pricing_item)
  }

  shuffleArray (array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i)
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  generateBarcode (length) {
    this.loadingBarcodeSpinner()
    const prefix = 'A'
    let barcode
    if (typeof length === 'undefined') length = 8
    barcode = prefix + this.shuffleArray('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'.repeat(length).split('')).join('').substring(0, 7)
    barcode = barcode.replace('CE', 'CD')
    return barcode
  }

  loadingBarcodeSpinner () {
    this.$data.loading = true
    setTimeout(() => {
      this.$data.loading = false
    }, 300)
  }

  autoGenerateBarcode () {
    this.$data.session.barcode = this.generateBarcode(8)
  }

  handleShowNewAttendeeForm () {
    this.$data.showNewAttendeeForm = true
  }

  handleCloseNewAttendeeForm () {
    this.$data.showNewAttendeeForm = false
  }

  async handleAddNewAttendee () {
    await this.fetchParticipantsList()
  }

  private handleRegtypeSpecificChange (value: boolean) {
    if (!value) {
      this.$data.option.reg_types = []
    }
  }

  /**
   * V-combobox doesn't allow populating v-model with value strings without showing the selected value in the component
   * chips instead of the label
   */
  get regTypeValues () {
    return this.$data.registration_types.map(object => object.value)
  }

  deletePricingItem (pricing_item_uuid: string) {
    this.$data.option.pricing = this.$data.option.pricing.filter(
      (pricing_item: any) => pricing_item.uuid !== pricing_item_uuid
    )
  }

  addPricingForOption () {
    const pricing_item = {
      uuid: Vue.prototype.$uuid.v4(),
      reg_type: '',
      tiered_pricing: {}
    }
    /**
     * Add each pricing tier's uuid to the pricing item so that it exists when we try to access it with v-model
     */
    this.$data.pricingTiers.forEach((tier: any) => {
      pricing_item.tiered_pricing[tier.uuid] = {
        price: '0',
        cancel_price: '0'
      }
    })
    this.$data.option.pricing.push(pricing_item)
  }

  deleteActivityPricingItem (pricing_item_uuid: string) {
    this.$data.activities_data = this.$data.activities_data.filter(
      (pricing_item: any) => pricing_item.uuid !== pricing_item_uuid
    )
  }

  /**
   * Update session, delete original speaker and add new speaker (if different)
   */
  async submit () {
    const sessionForm = (this.$refs.sessionForm as HTMLFormElement)
    const optionForm = (this.$refs.optionForm as HTMLFormElement)
    if (sessionForm.validate() && (!optionForm || optionForm?.validate())) {
      try {
        this.$data.submitting = true

        this.$data.session.start_date = this.convertFromEventTimezoneToUtc(this.$data.session.start_date)
        this.$data.session.end_date = this.convertFromEventTimezoneToUtc(this.$data.session.end_date)
        const payloadSession: any = {
          event_uuid: this.$data.event_uuid,
          data: this.$data.session
        }
        payloadSession.data.taxed = this.$data.option.taxed
        payloadSession.data.popup_details = this.$data.option.popup_details
        payloadSession.data.pricing = Array.isArray(this.$data.option.pricing) && this.$data.option.pricing.length > 0 ? this.$data.option.pricing : null
        payloadSession.data.visible = this.$data.option.visible
        payloadSession.data.regtype_specific = this.$data.option.regtype_specific
        payloadSession.data.reg_types = Array.isArray(this.$data.option.reg_types) && this.$data.option.reg_types.length > 0 ? this.$data.option.reg_types : null
        let sessionUuid = ''

        if (this.$data.session_uuid) {
          sessionUuid = this.$data.session_uuid
          payloadSession.session_uuid = this.$data.session_uuid
          await this.$store.dispatch('sessions/updateSession', payloadSession)
        } else {
          const responseSession = await this.$store.dispatch('sessions/createSession', payloadSession)
          sessionUuid = responseSession.data.uuid
        }

        if (this.$data.original_speaker_participant_uuid && this.$data.speaker_participant_uuid !== this.$data.original_speaker_participant_uuid) {
          const payloadDeleteSpeaker: any = {
            event_uuid: this.$data.event_uuid,
            session_uuid: sessionUuid,
            speaker_uuid: this.$data.original_speaker_uuid
          }
          await this.$store.dispatch('speaker/deleteSpeaker', payloadDeleteSpeaker)
        }

        if (this.$data.speaker_participant_uuid && this.$data.speaker_participant_uuid !== this.$data.original_speaker_participant_uuid) {
          const payloadAddSpeaker: any = {
            event_uuid: this.$data.event_uuid,
            session_uuid: sessionUuid,
            data: {
              order: 1,
              participant_uuid: this.$data.speaker_participant_uuid
            }
          }
          await this.$store.dispatch('speaker/addSpeaker', payloadAddSpeaker)
        }

        for (const existingActivityIndex in this.$data.activities_be) {
          const existingActivity = this.$data.activities_be[existingActivityIndex]
          const newActivityData = this.$data.activities_data.find((activity: any) => activity.uuid === existingActivity.uuid)
          if (newActivityData === undefined) {
            await this.$store.dispatch('sessions/deleteActivity', { event_uuid: this.$data.event_uuid, session_uuid: sessionUuid, activity_uuid: existingActivity.uuid })
          }
        }

        for (const activityIndex in this.$data.activities_data) {
          const activity = this.$data.activities_data[activityIndex]
          activity.caps = this.$data.regTypeCaps[activityIndex]
          const existingActvitiy = this.$data.activities_be.find((existingActivity: any) => activity.uuid === existingActivity.uuid)
          if (existingActvitiy === undefined) {
            const payloadAddActivity: any = {
              event_uuid: this.$data.event_uuid,
              session_uuid: sessionUuid,
              data: activity
            }
            await this.$store.dispatch('sessions/createActivity', payloadAddActivity)
          } else {
            const payloadUpdateActivity: any = {
              event_uuid: this.$data.event_uuid,
              session_uuid: sessionUuid,
              data: activity
            }
            await this.$store.dispatch('sessions/updateActivity', payloadUpdateActivity)
          }
        }

        Container.get(Notification).success('Session successfully updated.')
        await this.fetchActivities(sessionUuid)

        this.$router.push({
          name: 'level-two.modules.track.sessions'
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    } else {
      Container.get(Notification).error('Please correct errors on the form.')
    }
  }

  updateMinutesAfterCheckinTime () {
    if (!this.$data.session.auto_create_checkout_time || this.$data.session.scan_modes === '2' || this.$data.session.scan_modes === '3') {
      this.$data.session.minutes_after_checkin_time = ''
    }
  }

  showModal (activity) {
    Vue.set(this.$data.modals, activity.uuid, true)
  }

  closeModal (activity) {
    Vue.set(this.$data.modals, activity.uuid, false)
  }

  setCaps (activity_index, reg_type, value) {
    if (!this.$data.regTypeCaps[activity_index]) {
      this.$data.regTypeCaps[activity_index] = {}
    }
    this.$data.regTypeCaps[activity_index][reg_type] = value
  }
}
